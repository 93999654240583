<template>
  <div class="page">
    <van-loading v-if="state.loading" class="mt-5" color="#7232dd" size="36" vertical>
      {{ state.msg }}
    </van-loading>
    <van-empty v-else-if="state.error" image="error" :description="state.msg" />
    <div v-else class="content">
      <van-config-provider :theme-vars="themeVars">
        <van-tabs v-model:active="state.activeTab">
          <van-tab title="ChatGPT">
            <ChatgptSettings :fields="form.chatgpt" @updated="receiveEmits" />
          </van-tab>
          <van-tab title="Midjourney">
            <MidjourneySettings :fields="form.midjourney" @updated="receiveEmits" />
          </van-tab>
        </van-tabs>
      </van-config-provider>
      <van-action-bar>
        <van-action-bar-button type="danger" color="#7232dd" text="保存设置" loading-text="请稍候..."
          @click="handleUpdateSettings" />
      </van-action-bar>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import {
  ActionSheet,
  ActionBar,
  ActionBarButton, Empty, Image as VanImage, Toast, Tab, Tabs
} from "vant";
import { onMounted, reactive, toRaw } from "@vue/runtime-core";
import { setDocumentTitle } from "@/utils/domUtil";
import { getSettings, updateSettings } from "@/api/nijibot.service";
import { signaturePack } from "@/api/wechat.service";
import ChatgptSettings from "./components/ChatgptSettings";
import MidjourneySettings from "./components/MidjourneySettings";
export default {
  props: {
    name: {
      type: String,
      require: false,
    },
  },
  components: {
    ChatgptSettings,
    MidjourneySettings,
    [ActionSheet.name]: ActionSheet,
    [ActionBar.name]: ActionBar,
    [ActionBarButton.name]: ActionBarButton,
    [Empty.name]: Empty,
    [VanImage.name]: VanImage,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  setup(props) {
    const state = reactive({
      loading: true,
      error: false,
      activeTab: 0,
      msg: "请稍候",
      signaturePageStep: 'wait',  // wait待执行, success成功(仅此状态可以发起微信支付), failed失败
    });
    const form = reactive({
      chatgpt: "{}",
      midjourney: "{}",
    });

    const showErrorMessage = (msg) => {
      state.error = true;
      state.msg = msg;
    }

    const signaturePage = async () => {
      try {
        const url = /(Android)/i.test(navigator.userAgent)
          ? location.href.split("#")[0]
          : window.entryUrl;
        const { result } = await signaturePack(url);
        wx.config({
          appId: result.appId,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature,
          jsApiList: ["hideOptionMenu", "closeWindow"],
        });
        wx.ready(() => {
          state.signaturePageStep = "success";
          wx.hideOptionMenu();
        });
        wx.error((res) => {
          state.signaturePageStep = "failed";
          showErrorMessage(res.errMsg);
        });
      } catch (error) {
        state.signaturePageStep = "failed";
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        showErrorMessage(msg ? msg : "前端脚本异常/页面签名失败");
        if (!msg) console.log(error);
      }
    };
    onMounted(async () => {
      setDocumentTitle('尼基波特');
      signaturePage();
      const { name } = props;
      if (name == 'midjourney') {
        state.activeTab = 1;
      }
      try {
        const { result } = await getSettings();
        form.chatgpt = JSON.stringify(result.chatgpt);
        form.midjourney = JSON.stringify(result.midjourney);
        state.loading = false;
      } catch (error) {
        state.loading = false;
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        showErrorMessage(msg ? msg : "未知错误");
      }
    });

    const handleUpdateSettings = async () => {
      try {
        Toast.loading({
          message: '请稍候...',
          forbidClick: true,
          duration: 0,
        });
        await updateSettings({ ...toRaw(form) });
        Toast.success({
          message: "设置成功",
          forbidClick: true,
          overlay: true,
          duration: 4000
        });
        setTimeout(() => {
          wx.closeWindow();
        }, 3000);
      } catch (error) {
        Toast.clear();
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        showErrorMessage(msg ? msg : "未知错误");
      }
    }

    const themeVars = {
      tabsBottomBarColor: "#7232dd"
    };

    const receiveEmits = (key, data) => {
      form[key] = data;
    }

    return { state, form, handleUpdateSettings, themeVars, receiveEmits };
  },
};
</script>

<style scoped>
.content {
  padding-bottom: 100px;
}

.clickarea {
  padding: 16px;
}

.clickarea div {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border: 2px solid #e5e3ea;
  text-align: center;
  border-radius: 10px;
  margin: 5px 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.clickarea div.active {
  background-color: #be99ff4d;
  border: 3px solid #7232dd;
}

.emptytips {
  height: 122px;
  line-height: 122px;
  text-align: center;
  color: #7232dd;
}
</style>