<template>
  <div>

    <van-field v-model="form.version" is-link readonly input-align="right" name="version" label="版本" placeholder="点击选择"
      class="mt-2" @click="showPicker = true" />
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
    </van-popup>

    <van-field v-model="form.aspect" is-link readonly input-align="right" name="aspect" label="比例" placeholder="点击选择"
      class="mt-2" @click="showAspect = true" />
    <van-popup v-model:show="showAspect" position="bottom">
      <van-picker :columns="columnsAspect" @confirm="onConfirmAspect" @cancel="showAspect = false" />
    </van-popup>

    <van-field v-model="form.style" is-link readonly input-align="right" name="style" label="风格" placeholder="点击选择"
      class="mt-2" @click="showStyle = true" />
    <van-popup v-model:show="showStyle" position="bottom">
      <van-picker :columns="form.version == 'Niji5' ? columnsStyleNiji : columnsStyleDefault" @confirm="onConfirmStyle"
        @cancel="showStyle = false" />
    </van-popup>

    <van-field name="stylize" label="创意" class="mt-2">
      <template #input>
        <van-slider v-model="form.stylize" :min="50" :max="800" :step="5" active-color="#844ce59a" @change="formChange">
          <template #button>
            <div class="custom-button">{{ form.stylize }}</div>
          </template>
        </van-slider>
      </template>
    </van-field>

    <div class="text-center text-muted mt-3 small"><van-icon name="info-o" /> 这些参数可以直接在提示(Prompt)中覆盖 </div>

  </div>
</template>

<script>
import { onMounted, reactive, ref, toRaw } from "@vue/runtime-core";
import {
  Popup,
  Picker,
  Slider,
} from "vant";
export default {
  props: {
    fields: {
      required: false,
      type: String,
      default: () => {
        return "{}";
      },
    },
  },
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Slider.name]: Slider,
  },
  setup(props, { emit }) {
    const showPicker = ref(false);
    const showAspect = ref(false);
    const showStyle = ref(false);
    const form = reactive({
      version: "5.2",
      aspect: "1:1",
      style: "raw",
      stylize: 250,
    });
    const columns = [
      "5.2",
      "5.1",
      "Niji5"
    ];
    const columnsAspect = [
      "1:1",
      "1:2",
      "2:1",
      "3:4",
      "4:3",
      "9:16",
      "16:9",
    ];
    const columnsStyleDefault = ["raw"];
    const columnsStyleNiji = [
      "cute",
      "scenic",
      "original",
      "expressive",
    ];

    onMounted(() => {
      const fields = JSON.parse(props.fields);
      Object.assign(form, fields);
    });

    const formChange = () => {
      emit("updated", "midjourney", JSON.stringify(toRaw(form)));
    }

    const onConfirm = (value) => {
      form.version = value;
      if (value == 'Niji5') {
        form.style = 'cute';
      } else {
        form.style = 'raw';
      }
      showPicker.value = false;
      formChange();
    };

    const onConfirmAspect = (value) => {
      form.aspect = value;
      showAspect.value = false;
      formChange();
    };

    const onConfirmStyle = (value) => {
      form.style = value;
      showStyle.value = false;
      formChange();
    };

    return {
      showPicker, showAspect, showStyle, columns, columnsAspect, columnsStyleDefault, columnsStyleNiji,
      form, formChange, onConfirm, onConfirmAspect, onConfirmStyle
    }
  },
}
</script>

<style scoped>
.custom-button {
  min-width: 26px;
  padding: 0 5px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: #844ce5;
  border-radius: 100px;
}
</style>