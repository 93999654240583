<template>
  <div>

    <van-field v-model="form.model" is-link readonly input-align="right" name="model" label="GPT模型" placeholder="点击选择"
      class="mt-2" @click="showPicker = true" />
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
    </van-popup>

    <van-field v-model="form.role_name" is-link readonly input-align="right" name="model" label="GPT角色" placeholder="点击选择"
      class="mt-2" @click="showRole = true" />
    <van-popup v-model:show="showRole" position="bottom">
      <van-picker :columns-field-names="customFieldName" :columns="columnsRole" @confirm="onConfirmRole" @cancel="showRole = false" />
    </van-popup>

    <van-field name="max_tokens" label="最大返回" class="mt-2">
      <template #input>
        <van-slider v-model="form.max_tokens" :min="300" :max="3000" :step="10" active-color="#844ce59a"
          @change="formChange">
          <template #button>
            <div class="custom-button">{{ form.max_tokens }}</div>
          </template>
        </van-slider>
      </template>
    </van-field>

    <van-field name="history_num" label="历史消息" class="mt-2">
      <template #input>
        <van-slider v-model="form.history_num" :min="0" :max="20" :step="2" active-color="#844ce59a" @change="formChange">
          <template #button>
            <div class="custom-button">{{ form.history_num }}</div>
          </template>
        </van-slider>
      </template>
    </van-field>

    <div class="text-center text-muted mt-3 small"><van-icon name="info-o" /> 最大返回 x 最大返回不能大于4000 </div>

  </div>
</template>

<script>
import {
  Toast
} from "vant";
import { onMounted, reactive, ref, toRaw } from "@vue/runtime-core";
import { getRoles } from "@/api/nijibot.service";
import {
  Popup,
  Picker,
  Slider,
} from "vant";
export default {
  props: {
    fields: {
      required: false,
      type: String,
      default: () => {
        return "{}";
      },
    },
  },
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Slider.name]: Slider,
  },
  setup(props, { emit }) {
    const showPicker = ref(false);
    const showRole = ref(false);
    const form = reactive({
      model: "gpt-3.5-turbo",
      max_tokens: 300,
      history_num: 10,
      role_id: 0,
      role_name: "自由对话",
    });
    const columns = [
      "gpt-3.5-turbo",
      "gpt-3.5-turbo-0613",
      "gpt-4-0613",
    ];
    const columnsRole = reactive([{id: 0, title: "自由对话"}]);

    const customFieldName = {
      text: 'title',
    };
    const loadRoles = async () => {
      try {
        const { result } = await getRoles();
        columnsRole.length = 0;
        Object.assign(columnsRole, result.items);
        columnsRole.unshift({id: 0, title: "自由对话"});
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast.error(msg ? msg : "未知错误");
      }
    }

    onMounted(() => {
      const fields = JSON.parse(props.fields);
      Object.assign(form, fields);
      loadRoles();
    });

    const formChange = () => {
      emit("updated", "chatgpt", JSON.stringify(toRaw(form)));
    }

    const onConfirm = (value) => {
      form.model = value;
      showPicker.value = false;
      formChange();
    };

    const onConfirmRole = (item) => {
      form.role_id = item.id;
      form.role_name = item.title;
      showRole.value = false;
      formChange();
    };

    return { showPicker, showRole, columns, columnsRole, customFieldName, form, formChange, onConfirm, onConfirmRole }
  },
}
</script>

<style scoped>
.custom-button {
  min-width: 26px;
  padding: 0 5px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: #844ce5;
  border-radius: 100px;
}
</style>